/* Calendar */
div.calendar {
  width: 100%;
  border: 1px solid var(--outline-color);
  overflow: hidden;

  /* Colors */
  --foreground-color: #ffffff;
  --foreground-color-darken: #f8f9fa;
  --font-color: #555555;
  --outline-color: #d8dae3;
  --primary-color: #6fb55d;
  --primary-color-lighten: #5ece41;
  --secondary-color-darken: #006d8f;
  --secondary-color: #ebc637;
  --secondary-color-lighten: #ffe375;
  --tertiary-color: #5cbfdc;
  --tertiary-color-lighten: #acecff;
  --quaternary-color: #f37341;
  --quaternary-color-lighten: #ffa581;
  --error-color: #ff6d6d;
  --error-color-lighten: #f8d1d1;
}

.calendar * {
  font-family: ProductSans, sans-serif;
  font-size: 1rem;
}

.calendar button {
  color: var(--font-color);
  background: var(--foreground-color);
  border-radius: 0;
  transition: all 0s;
}

.calendar div:nth-child(1) button {
  padding: 0;
}

.calendar div:nth-child(1) div div div div abbr {
  font-size: 0.78rem;
  color: black;
}

.calendar div:nth-child(2) button {
  display: grid;
  place-content: center;
}

.calendar div:nth-child(2) button abbr {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  z-index: 11;
}

.calendar button:disabled {
  background: var(--outline-color);
  color: var(--error-color);
}

/* Timetable */
.timetable button.bootcamp {
  background: var(--quaternary-color);
  color: var(--foreground-color);
}

.calendar button.bootcamp:enabled:hover,
.calendar button.bootcamp:enabled:focus {
  background: var(--quaternary-color-lighten);
  color: var(--font-color);
}

.timetable button.main {
  background: var(--secondary-color);
  color: var(--foreground-color);
}

.calendar button.main:enabled:hover,
.calendar button.main:enabled:focus {
  background: var(--secondary-color-lighten);
  color: var(--font-color);
}

.timetable button.support {
  background: var(--tertiary-color);
  color: var(--foreground-color);
}

.calendar button.support:enabled:hover,
.calendar button.support:enabled:focus {
  background: var(--tertiary-color-lighten);
  color: var(--font-color);
}

/* Selected */
.calendar div:nth-child(2) button.selected {
  background: var(--primary-color);
  color: var(--foreground-color);
}

/* Hover */
.calendar div button:enabled:hover,
.calendar div button:enabled:focus,
.calendar button.selected:enabled:hover,
.calendar button.selected:enabled:focus,
.calendar button:enabled:focus {
  background: var(--primary-color-lighten);
  color: var(--foreground-color);
}

.calendar div button:enabled:hover {
  position: relative;
  overflow: visible !important;
  z-index: 10;
}

.calendar div:nth-child(2) button:enabled:hover {
  position: relative;
  overflow: visible !important;
}

.startWeekCalendar div:nth-child(2) button:enabled:hover:after {
  content: "";
  position: absolute;
  width: 1300%;
  height: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-color-lighten);
  color: var(--font-color);
}
